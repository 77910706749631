import React from "react";
import { BrowserRouter, Routes, Route} from "react-router-dom";
import Login from "../view/Login";
import Register from "../view/Register";
import Quotes from "../view/Quotes";
import { LoginContextProvider }  from '../Context/Logincontext'
import Client from "../view/Client";
import Users from "../view/Users";
import Add from "../view/Admin/Add";
import Products from "../view/Products";
import AddClient from "../view/AddClient";
import AddQuotes from "../view/AddQuotes";
import Pdf from "../Component/Hooks/PdfMarmol/Pdf";
import AddCompany from "../view/AddCompany";
import Config from "../view/Config";
import Texture from "../view/Texture";
import AddTexture from "../view/AddTexture";
import AddProduct3D from "../view/AddProduct3D";
import MenuConfig from "../view/Admin/MenuConfig";
import CompanyView from "../view/Admin/CompanyView";
import Prueba from "../view/prueba";
import BarraConSalpicadero from "../json/BarraConSalpicadoro";
import Barra from "../json/Barra";
import Circulo from "../json/Circulo";
import CirculoGrande from "../json/CirculoGrande";
import MesaenL from "../json/MesaenL";
import MesaenLGrande from "../json/MesonenLGrande";
import AddTest from "../view/Admin/Add";
import ForgottenPassword from "../view/ForgottenPassword";

function Router() {

    return (
        <>
            <LoginContextProvider>
                <BrowserRouter>
                    <Routes>
                        <Route exact index element={<Login/>}/>
                        <Route exact path="/ajustes" element={<Config />} />
                        <Route exact path="/Pruebas" element={<AddTest />} />
                        <Route exact path="/Agregar_Textura" element={<AddTexture />} />
                        <Route exact path="/Texturas" element={<Texture />} />
                        <Route exact path="/Crear_Empresa" element={<AddCompany />} />
                        <Route exact path="/Login" element={<Login />} />
                        <Route exact path="/Registrar" element={<Register />} />
                        <Route exact path="/Cotizaciones" element={<Quotes />} />
                        <Route exact path="/Clientes" element={<Client />} />
                        <Route exact path="/Usuarios" element={<Users />} /> 
                        <Route exact path="/Productos" element={<Products />} />                 
                        <Route exact path="/Agregar" element={<Add />} />  
                        <Route exact path="/Agregar_Clientes" element={<AddClient />} />                 
                        <Route exact path="/Agregar_Productos" element={<AddProduct3D />} /> 
                        <Route exact path="Menu_Configuracion" element={<MenuConfig />} />  
                        <Route exact path="/Empresas" element={<CompanyView />} />  
                        <Route exact path="/RecuperarContraseña" element={<ForgottenPassword />} />  
                        <Route exact path="/Agregar_Cotizacion" element={<AddQuotes />} /> 
                        <Route exact path="/VistapreviaPdf" element={<Pdf />} />
                        <Route exact path="/Barraconsalpicadero" element={<BarraConSalpicadero />} />
                        <Route exact path="/Barra" element={<Barra />} />
                        {/* <Route exact path="/Circulo" element={<Circulo />} /> */}
                        <Route exact path="/Mesaredonda" element={<CirculoGrande />} />
                        {/* <Route exact path="/Mesaenl" element={<MesaenL />} /> */}
                        <Route exact path="/Mesaenlgrande" element={<MesaenLGrande />} />
                        <Route exact path="/Iniciar_Sesión" element={<Login />} />            
                    </Routes>
                </BrowserRouter> 
            </LoginContextProvider>
        </>
    );
}
  
export default Router;