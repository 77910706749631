import { useContext, useEffect, useState } from "react"
import { NavLink } from "react-router-dom"
import Swal from 'sweetalert2'
import invisible from '../Assest/invisibleKey.png'
import visible from '../Assest/visibleKey.png'
import '../styles/addCompany.css'
import LoginContext from "../Context/Logincontext"
import { urlApiServer } from "../Config/Config"
import { ImExit } from 'react-icons/im'
import CloseButton from "../Component/CloseButton"
import { TbGeometry } from 'react-icons/tb'

const AddCompany = () => {

    const urlCreate = urlApiServer + "createCompany";
    const urlGetUser = urlApiServer + "users"

    const [name, setName] = useState()
    const [surNames, setSurnames] = useState()
    const [type, setType] = useState(null)
    const [numbreDocument, setNumbreDocument] = useState()
    const [phone, setPhone] = useState()
    const [email, setEmail] = useState()
    const [emailValidation, setEmailValidation] = useState("");
    const [password, setPassword] = useState()
    const [checkPassword, setCheckpassword] = useState()
    const [companyName, setCompanyName] = useState()
    const [companyNit, setCompanyNit] = useState()
    const [companyPhone, setCompanyPhone] = useState()
    const [companyMobile, setCompanyMobile] = useState()
    const [companyAddres, setCompanyAddres] = useState()
    const [companyType, setCompanyType] = useState()

    const { infoUser, setInfoUser } = useContext(LoginContext)
    const [visibleKey1, setVisibleKey1] = useState(true)
    const [visibleKey2, setVisibleKey2] = useState(true)
    const [userGetTable, setUserGetTable] = useState([])

    async function handleName(e) {

        const name = e.target.value
        if (name.length > 30) {
            Swal.fire({
                icon: 'warning',
                text: 'Nombre muy largo'
            })
        } else {
            await setName(name)
        }
    }
    async function handleSurName(e) {
        const surNames = e.target.value
        if (surNames.length > 30) {
            Swal.fire({
                icon: 'warning',
                text: 'Apillos muy largo'
            })
        } else {
            await setSurnames(surNames)
        }
    }
    async function handleType(e) {
        const type = e.target.value
        if (type === null) {
            Swal.fire({
                icon: 'warning',
                text: 'Seleccione un tipo de documento'
            })
        } else {
            await setType(type)
        }
    }

    async function handleTypeCompany(e) {
        const type = e.target.value
        if (type === null) {
            Swal.fire({
                icon: 'warning',
                text: 'Seleccione un tipo de empresa'
            })
        } else {
            await setCompanyType(type)
        }
    }

    async function handleNumberDocument(e) {
        const numbreDocument = e.target.value
        if (surNames.length > 30) {
            Swal.fire({
                icon: 'warning',
                text: 'Documento muy larga'
            })
        } else {
            await setNumbreDocument(numbreDocument)
        }
    }
    async function handlePhone(e) {
        const phone = e.target.value
        if (surNames.length > 30) {
            Swal.fire({
                icon: 'warning',
                text: 'Celular muy larga'
            })
        } else {
            await setPhone(phone)
        }
    }
    async function handleEmail(e) {
        const email = e.target.value
        const validation = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/
        if (validation.test(email)) {
            setEmailValidation("correo valido")
            setEmail(email);
        } else {
            setEmailValidation("correo invalido")
        }
    }
    async function handlePassword(e) {
        const password = e.target.value
        if (password.length > 30) {
            Swal.fire({
                icon: 'warning',
                text: 'Contraseña muy larga'
            })
        } else {
            await setPassword(password)
        }
    }
    async function handleCheckPassword(e) {
        const checkPassword = e.target.value
        if (checkPassword === password) {
            setCheckpassword(checkPassword);
        }
    }
    async function handleNameCompany(e) {
        const companyName = e.target.value.replace(/\s+/g, '_');
        await setCompanyName(companyName)
    }
    async function handleCompanyNit(e) {
        const companyNit = e.target.value.replace(/[^0-9-_]/g, '');
        setCompanyNit(companyNit);
    }
    async function handleCompanyPhone(e) {
        const companyPhone = e.target.value
        await setCompanyPhone(companyPhone)
    }

    async function handleCompanyAddres(e) {
        const addres = e.target.value
        await setCompanyAddres(addres)
    }


    // useEffect(() => {
    //     (async function () {

    //         const InicioHeaders = new Headers();
    //         InicioHeaders.append("Content-Type", "application/json");
    //         // InicioHeaders.append("company", infoUser.company);

    //         const requesoption = {
    //             method: 'GET',
    //             headers: InicioHeaders,
    //             redirect: 'follow'
    //         }

    //         await fetch(urlGetUser, requesoption)
    //             .then(response => response.json())
    //             .then(quotesResponse => {
    //                 setUserGetTable(quotesResponse);
    //             })
    //             .catch(error => {
    //                 // console.log(error);
    //             });


    //     }())
    //     // console.log("rfffnnf",infoUser)

    // }, [])

    async function Registerprofile() {
        console.log('Entrando Registerprofile')
        const adminExists = userGetTable.some(user => user.correo === email);
        if (adminExists) {
            Swal.fire({
                icon: 'warning',
                title: 'Lo sentimos',
                text: 'No fue posible continuar con la creacion debido a que el usuario ya existe.'
            });
            return;
        }
        if (checkPassword === password) {
            if (emailValidation === "correo valido") {
                const data = JSON.stringify({
                    nameCompany: companyName,
                    companyNit: companyNit,
                    companyPhone: companyPhone,
                    companyMobile: companyPhone,
                    companyAddres: companyAddres,
                    name: name,
                    surNames: surNames,
                    type: type,
                    numbreDocument: numbreDocument,
                    phone: phone,
                    email: email,
                    password: password,
                    checkPassword: checkPassword,
                    companyType: companyType,
                    rol: "Administrador"
                })
                const registerHeaders = new Headers();
                registerHeaders.append("Content-Type", "application/json")

                const requesoption = {
                    method: 'POST',
                    headers: registerHeaders,
                    body: data,
                    redirect: 'follow'
                }
                await fetch(urlCreate, requesoption)
                    .then(response => {
                        console.log("RESPONSE = ",response)
                        if (response.status === 200) {
                            Swal.fire({
                                icon: 'success',
                                title: 'Registro correcto',
                                text: 'has registrado correctamente una empresa',
                                confirmButtonText: 'Aceptar',
                            })
                        }else{
                            Swal.fire({
                                icon: 'error',
                                title: 'Error!',
                                text: response.message,
                                confirmButtonText: 'Aceptar',
                            })
                        }
                    })
                    .catch(error => {
                        console.log(error);
                    });
            } else {
                Swal.fire({
                    icon: 'error',
                    title: 'Error!',
                    text: 'Tu correo esta en un formato invalido',
                    confirmButtonText: 'Aceptar',
                })
            }
        } else {
            Swal.fire({
                icon: 'error',
                title: 'Error!',
                text: 'Las claves no coinciden',
                confirmButtonText: 'Aceptar',
            })
        }
    }


    return (
        <>
            <div className="containerLogoAdd containerLogoAddFixed">
                <NavLink to="/Menu_Configuracion" className='closeButton'>
                <span className="tooltipProductMenos">Configuracion</span>
                    <TbGeometry className='iconNav' />
                </NavLink>
                <p className='titleWithoutCompany'>Crear empresa</p>
                <div className='closeIntContainer'>
                    {/* <NavLink onClick={Cerrar} className='closeButton'>
                        <span className="tooltipProductMenos">Cerrar Sesión       </span>
                        <ImExit  className='iconNav'/>
                    </NavLink> */}
                    <CloseButton />
                </div>
            </div>
            <div id='overallContainerLogin' className='overallContainerLogin'>
                <div className='drawerFormAddCuotes drawerFormAddCuotesAdmin'>
                    <form className='ContainerCompanyAddForm'>
                        <div className='containerInfo'>
                            <div className='infoOne'>
                                <p className='personalInformation'>Informacion del administrador</p>
                                <div className='containerNameClient'>
                                    <div className="inputUsersContainer">
                                        <input
                                            type="text"
                                            name="name"
                                            id="name"
                                            onChange={handleName}
                                            className="inputUsers"
                                            placeholder=" "
                                        />
                                        <label htmlFor="name">Nombre</label>
                                    </div>
                                    <div className="inputUsersContainer">
                                        <input
                                            type="text"
                                            name="surNames"
                                            id="surNames"
                                            onChange={handleSurName}
                                            className="inputUsers"
                                            placeholder=" "
                                        />
                                        <label htmlFor="name">Apellidos</label>
                                    </div>
                                    <div className="inputUsersContainer">
                                        <select
                                            name="Type"
                                            id="Type"
                                            onChange={handleType}
                                            className="inputSelectNit"
                                        >
                                            <option value="Type">Tipo de documento:</option>
                                            <option value="Cedula de ciudadania">Cedula de ciudadania</option>
                                            <option value="Cedula de extranjeria">Cedula de extranjeria</option>
                                            <option value="Tarjeta de identidad">Tarjeta de identidad</option>
                                        </select>
                                    </div>
                                    <div className="inputUsersContainer">
                                        <input
                                            type="number"
                                            onWheel={(e) => e.target.blur()}
                                            name="citizenshipCard"
                                            id="citizenshipCard"
                                            onChange={handleNumberDocument}
                                            className="inputUsers"
                                            placeholder=" "
                                        />
                                        <label htmlFor="name">Numero del documento</label>
                                    </div>
                                    <div className="inputUsersContainer">
                                        <input
                                            type="number"
                                            onWheel={(e) => e.target.blur()}
                                            name="Phone"
                                            id="Phone"
                                            onChange={handlePhone}
                                            className="inputUsers"
                                            placeholder=" "
                                        />
                                        <label htmlFor="name">Numero de Telefono</label>
                                    </div>
                                    <div className="inputUsersContainer">
                                        <input
                                            type="email"
                                            name="email"
                                            id="email"
                                            onChange={handleEmail}
                                            className="inputUsers"
                                            placeholder=" "
                                            autoComplete="off"
                                        />
                                        <label htmlFor="name">Correo electronico</label>
                                    </div>
                                    <div className="inputPasswordContainer">
                                        <input
                                            type={visibleKey1 ? 'password' : 'text'}
                                            name="password"
                                            id="password"
                                            onChange={handlePassword}
                                            className="inputPasswordText"
                                            placeholder=" "
                                            autoComplete="new-password"

                                        />
                                        <label htmlFor="name">Contraseña</label>
                                        <NavLink onClick={() => { visibleKey1 ? setVisibleKey1(false) : setVisibleKey1(true) }}><img src={visibleKey1 ? invisible : visible} alt="Key" className='password4'></img></NavLink>
                                    </div>
                                    <div className="inputPasswordContainer">
                                        <input
                                            type={visibleKey2 ? 'password' : 'text'}
                                            name="confirmPassword"
                                            id="confirmPassword"
                                            onChange={handleCheckPassword}
                                            className="inputPasswordText"
                                            placeholder=" "
                                            autoComplete="new-password"

                                        />
                                        <label htmlFor="name">Confirmar contraseña</label>
                                        <NavLink onClick={() => { visibleKey2 ? setVisibleKey2(false) : setVisibleKey2(true) }}><img src={visibleKey2 ? invisible : visible} alt="Key" className='password4'></img></NavLink>
                                    </div>
                                </div>
                            </div>
                            <div className='infoTwo'>
                                <p className='personalInformation'>Informacion de la empresa</p>
                                <div className='containerContactClient'>
                                    <div className="inputUsersContainer">
                                        <input
                                            type="text"
                                            name="companyName"
                                            id="companyName"
                                            onChange={handleNameCompany}
                                            className="inputUsers"
                                            placeholder=" "
                                        />
                                        <label htmlFor="name">Nombre de la empresa</label>
                                    </div>
                                    <div className="inputUsersContainer">
                                        <input
                                            type="text"
                                            name="companyNit"
                                            id="companyNit"
                                            onChange={handleCompanyNit}
                                            className="inputUsers"
                                            placeholder=" "
                                        />
                                        <label htmlFor="name">Nit de la empresa</label>
                                    </div>
                                    <div className="inputUsersContainer">
                                        <input
                                            type="number"
                                            onWheel={(e) => e.target.blur()}
                                            name="CompanyPhone"
                                            id="CompanyPhone"
                                            onChange={handleCompanyPhone}
                                            className="inputUsers"
                                            placeholder=" "
                                        />
                                        <label htmlFor="name">Telefono de la empresa</label>
                                    </div>
                                    <div className="inputUsersContainer">
                                        <input
                                            type="text"
                                            name="CompanyAddres"
                                            id="CompanyAddres"
                                            onChange={handleCompanyAddres}
                                            className="inputUsers"
                                            placeholder=" "
                                        />
                                        <label htmlFor="name">Direccion de la empresa</label>
                                    </div>

                                    <div className="inputUsersContainer">
                                        <select
                                            name="Type"
                                            id="Type2"
                                            onChange={handleTypeCompany}
                                            className="inputSelectNit"
                                        >
                                            <option value="Type">Tipo de empresa:</option>
                                            <option value="Marmol">Marmol</option>
                                            <option value="Generica">Generica</option>
                                        </select>
                                    </div>

                                    <div className="buttonContainer">
                                        <div onClick={Registerprofile} className="button neon_EffectLogin"><p>Crear</p></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </>
    )
}
export default AddCompany;