import { useRef, useEffect, useState, useContext } from "react";
import LoginContext from "../../Context/Logincontext";
import { NavLink } from "react-router-dom";
import CloseButton from "../../Component/CloseButton";
import {AiFillCloseCircle} from 'react-icons/ai'
import './adminConfig.css'

const MenuConfig = () => {
    const { infoUser, setInfoUser } = useContext(LoginContext)





    return (
        <>
            <div className="containerLogoAdd">
                <p className='titleWithoutCompany'>Configuracion</p>
                <div className='closeIntContainer'>
                    {/* <NavLink onClick={Cerrar} className='closeButton'>
                        <span className="tooltipProductMenos">Cerrar Sesión       </span>
                        <ImExit  className='iconNav'/>
                    </NavLink> */}
                    <CloseButton />
                </div>
            </div>
            <div id='overallContainerLogin' className='overallContainerLogin'>
                <div className='containerConfigAdmin'>
                    <div className='containerInfoConfigAdmin'>
                        <NavLink to="/Pruebas" className='navIconAddCompany'>
                            <div className="buttonContainer">
                                <button className="button neon_EffectLogin"><p>Subir Modelos</p></button>
                            </div>
                        </NavLink>
                        <NavLink to="/Empresas" className='navIconAddCompany'>
                            <div className="buttonContainer">
                                <button className="button neon_EffectLogin"><p>Empresas</p></button>
                            </div>
                        </NavLink>
                        <NavLink to="/#" className='navIconAddCompany'>
                            <div className="buttonContainer">
                                <button className="button neon_EffectLogin"><p>Administradores</p></button>
                            </div>
                        </NavLink>
                        <NavLink to="/Crear_Empresa" className='navIconAddCompany'>
                            <AiFillCloseCircle className='iconAdd' />
                            <p>Atras</p>
                        </NavLink>
                    </div>
                </div>
            </div>
        </>
    );
};

export default MenuConfig;