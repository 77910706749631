import React, { useContext, useEffect, useRef, useState } from 'react';
import '../../styles/Nav.css'
import { BsFillGearFill } from 'react-icons/bs'
import { FaShoppingBag, FaUsers, FaTable, FaUserFriends, FaUserPlus, FaBars, FaBusinessTime } from 'react-icons/fa'
import { MdTexture } from 'react-icons/md'
import { NavLink } from 'react-router-dom'
import LoginContext from '../../Context/Logincontext';
import UserConfiguredSettings from '../UserConfiguredSettings/UserConfiguredSettings';
import { urlApiServer } from '../../Config/Config'

const Nav = () => {
  const urlgetConfig = urlApiServer + "getConfig";
  const { infoUser, setInfoUser } = useContext(LoginContext)
  const divRef = useRef(null);
  const [firstTouch, setFirsTouch] = useState(false)
  const [oldConfig, setOldConfig]=useState("")  
  const [mainColor, setMainColor]=useState("")  

  useEffect(()=>{
    (async function (){
        
        const InicioHeaders = new Headers();
        InicioHeaders.append("Content-Type", "application/json")

        const requesoption ={
            method: 'GET',
            headers: InicioHeaders,
            redirect: 'follow'
        }
        await fetch(urlgetConfig, requesoption).then(response => response.json()).then(quotesResponse=>{
            setOldConfig(quotesResponse)
        })
    }())


},[])

  useEffect(()=>{
    if (oldConfig) {
      const foundConfig = oldConfig.find(obj => obj.empresa === infoUser.company);
      // console.log('foundConfigpp: ', foundConfig)
      if (foundConfig) {
        const {maincolor} = foundConfig;
        setMainColor(JSON.parse(maincolor));

      }
    }
  },[oldConfig, infoUser])

  const handleClickOutside = (event) => {
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  function mostrarNav() {
    if (document.getElementsByClassName('divNav')[0].style.display === 'block') {
      if (!firstTouch) {

      document.getElementsByClassName('divNav')[0].style.display = 'block';
      document.getElementsByClassName('menuButton2')[0].style.position = 'relative';
      document.getElementsByClassName('menuButton2')[0].style.left = '0px';
      document.getElementsByClassName('containerNav ')[0].style.webkitBackdropFilter = 'blur(20px)';
      document.getElementsByClassName('containerNav ')[0].style.backdropFilter = 'blur(20px)';
      setFirsTouch(true)

      } else {
        document.getElementsByClassName('divNav')[0].style.display = 'none';
        document.getElementsByClassName('menuButton2')[0].style.position = 'fixed';
        document.getElementsByClassName('menuButton2')[0].style.backgroundColor = 'transparent';
        document.getElementsByClassName('containerNav ')[0].style.webkitBackdropFilter = 'none';
        document.getElementsByClassName('containerNav ')[0].style.backdropFilter = 'none';
        document.getElementsByClassName('menuButton2')[0].style.left = 'none';
      }
    } else {
      if (firstTouch) {

        document.getElementsByClassName('divNav')[0].style.display = 'block';
        document.getElementsByClassName('menuButton2')[0].style.position = 'relative';
        document.getElementsByClassName('menuButton2')[0].style.left = '0px';
        document.getElementsByClassName('containerNav ')[0].style.webkitBackdropFilter = 'blur(20px)';
        document.getElementsByClassName('containerNav ')[0].style.backdropFilter = 'blur(20px)';

      } else {
        document.getElementsByClassName('divNav')[0].style.display = 'none';
        document.getElementsByClassName('menuButton2')[0].style.position = 'fixed';
        document.getElementsByClassName('menuButton2')[0].style.backgroundColor = 'transparent';
        document.getElementsByClassName('containerNav ')[0].style.webkitBackdropFilter = 'none';
        document.getElementsByClassName('containerNav ')[0].style.backdropFilter = 'none';
        document.getElementsByClassName('menuButton2')[0].style.left = 'none';
        setFirsTouch(true)

      }
    }
  }
  var width = window.innerWidth;
  // console.log('width: ', width)
  useEffect(()=>{
    document.addEventListener("DOMContentLoaded", async function() {
  
      if (width > 801) {
        var menuButton = document.getElementsByClassName('menuButton2')[0];
        if (menuButton) {
          menuButton.style.backgroundColor = `rgba(${mainColor[0]},${mainColor[1]},${mainColor[2]},${mainColor[3]})`;
        }
      }
    });
  },[width])


  return (
    <>
      <div className='containerNav' ref={divRef}>
        <a className="menuButton2">
          <button className='divIcon'>
            <FaBars className='faBarsIcon' onClick={mostrarNav} />
          </button>
          <UserConfiguredSettings />
        </a>
        <div className='divNav'>
          {((infoUser.role === 'Administrador')) ? (
            <nav className='NavLinkContainer'>
              <NavLink className="activeNav" to="/ajustes">
                <BsFillGearFill className='iconNav' />

              </NavLink>
              <p className='textNavLink'><strong>Ajustes</strong></p>
              <NavLink to="/Productos" className="activeNav">
                <FaShoppingBag className='iconNav' />
              </NavLink>
              <p className='textNavLink'><strong>Productos</strong></p>
              {
                (infoUser.companyType === "Marmol")&&(
                  <>
                                    <NavLink to="/Texturas" className="activeNav">
                  <MdTexture className='iconNav' />
                </NavLink>
                <p className='textNavLink'><strong>Material</strong></p>
                  </>
                )
              }
              <NavLink to="/Clientes" className="activeNav">
                <FaUsers className='iconNav' />
              </NavLink>
              <p className='textNavLink'><strong>Clientes</strong></p>
              <NavLink to="/Cotizaciones" className="activeNav">
                <FaTable className='iconNav' />
              </NavLink>
              <p className='textNavLink'><strong>Cotización</strong></p>
              <NavLink to="/Usuarios" className="activeNav">
                <FaUserFriends className='iconNav' />
              </NavLink>
              <p className='textNavLink'><strong>Usuarios</strong></p>
              <NavLink to="/Registrar" className="activeNav">
                <FaUserPlus className='iconNav' />
              </NavLink>
              <p className='textNavLink'><strong>Registrar</strong></p>
            </nav>
          ) : (
            (infoUser.role === 'SuperUsuario') ? (
              <>
                <NavLink to="/Crear_Empresa" className="activeNav">
                  <FaBusinessTime className='iconNav' />
                </NavLink>
                <p className='textNavLink'><strong>Crear Empresa</strong></p>
              </>
            ) : (
              <>
                <nav className='NavLinkContainer'>
                  <NavLink to="/Productos" className="activeNav">
                    <FaShoppingBag className='iconNav' />
                  </NavLink>
                  <p className='textNavLink'>Productos</p>
                  <NavLink to="/Clientes" className="activeNav">
                    <FaUsers className='iconNav' />
                  </NavLink>
                  <p className='textNavLink'>Clientes</p>
                  <NavLink to="/Cotizaciones" className="activeNav">
                    <FaTable className='iconNav' />
                  </NavLink>
                  <p className='textNavLink'>Cotización</p>
                </nav>
              </>
            )
          )}
        </div>
      </div>
    </>
  )
}

export default Nav