import React, { useContext, useEffect, useState } from 'react'
import invisible from '../Assest/invisibleKey.png'
import visible from '../Assest/visibleKey.png'
import { NavLink } from 'react-router-dom'
import LoginContext from '../Context/Logincontext';
import { urlApiServer } from '../Config/Config'
import '../styles/Login.css'
import Swal from 'sweetalert2'
import Loader from '../Component/Loader';
import logo from '../Assest/LogoAppcotizaTransp.png'
import urlHomeVideo from '../Assest/homeVideo.mp4'
import { FaWhatsapp, FaKey  } from "react-icons/fa";

const urlinicio = urlApiServer + "Login";
// const urlGetNameCompnay = urlApiServer + "nameCompany";

const Login = () => {

    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [company, setCompany] = useState()
    const [visibleKey, setVisibleKey] = useState(true)
    const [nameCompany, setNameCompany] = useState("")
    const [disabledBottom, setDisabledBottom] = useState(false);
    const [showVideo, setShowVideo] = useState(false);
    const { infoUser, setInfoUser } = useContext(LoginContext)

    async function handleEmail(e) {
        await setEmail(e.target.value)
    }

    async function handlePassword(e) {
        await setPassword(e.target.value)
    }

    // useEffect(() => {
    //     (async function () {
    //         const InicioHeaders = new Headers();
    //         InicioHeaders.append("Content-Type", "application/json")

    //         const requesoption = {
    //             method: 'GET',
    //             headers: InicioHeaders,
    //             redirect: 'follow'
    //         }
    //         await fetch(urlGetNameCompnay, requesoption).then(response => response.json()).then(quotesResponse => {
    //             setNameCompany(quotesResponse)
    //         })
    //     }())
    // }, [])


    useEffect(() => {
        if (infoUser.role === 'SuperUsuario') {
            if (infoUser.login === true) {
                window.location.href = './Crear_Empresa';
            }
        }
        else {
            if (infoUser.login === true) {
                window.location.href = './Cotizaciones';
            }
        }

    }, []);

    // useEffect(() => {
    //     window.addEventListener('keydown', handleKeyDown);

    //     return () => {
    //         window.removeEventListener('keydown', handleKeyDown);
    //     };
    // }, [email, password]);

    // const handleKeyDown = (event) => {
    //     if (event.key === 'Enter') {
    //         event.preventDefault();
    //         // El usuario ha presionado la tecla 'Enter'
    //         // console.log('Tecla Enter presionada');
    //         Loginprofile()
    //         // console.log("email: ", email)
    //     }
    // };

    const handleVideo = () => {
        setShowVideo(!showVideo)
    };



    // async function Loginprofile() {
    const Loginprofile = async () => {
        setDisabledBottom(true)
        const data = JSON.stringify({
            company: company,
            email: email,
            password: password
        })
        const InicioHeaders = new Headers();
        InicioHeaders.append("Content-Type", "application/json")

        const requesoption = {
            method: 'POST',
            headers: InicioHeaders,
            body: data,
            redirect: 'follow'
        }
        await fetch(urlinicio, requesoption).then(response => response.json())
            .then(dataResponse => {
                setDisabledBottom(false)
                if (200 === dataResponse.status) {
                    const user = dataResponse.data[0]
                    let config

                    if (dataResponse.config.length > 0) {
                        config = dataResponse.config[0]
                    } else {
                        config = {
                            neon: "Red",
                            innericoncolor: "Red",
                            iconcolor: "white",
                            tittle: "white",
                            fontcolor: "white",
                            maincolor: "[82, 81, 81, 0.691]",
                            secondarycolor: "[82, 81, 81, 0.3]",
                            logo: "defaultLogo2.png",
                            backgrounddefault: "default.jpeg",
                        }
                    }
                    const textArrayMain = config.maincolor;
                    const strippedTextMain = textArrayMain.slice(1, -1); // Elimina los corchetes
                    const mainColorReal = strippedTextMain.split(',').map(item => parseFloat(item.trim())); // Convierte a números

                    const textArraySecondary = config.secondarycolor;
                    const strippedTextSecondary = textArraySecondary.slice(1, -1); // Elimina los corchetes
                    const secondaryColorReal = strippedTextSecondary.split(',').map(item => parseFloat(item.trim())); // Convierte a números

                    setInfoUser({
                        login: true,
                        name: user.nombre,
                        email: email,
                        role: user.roles,
                        company: 'empresa_' + user.empresa,
                        companyType: dataResponse.companyType,
                        // ...infoUser,
                        colorNeonAndButtons: config.neon ? config.neon : "Red",
                        backgroundColorIcons: config.innericoncolor ? config.innericoncolor : "Red",
                        colorIcons: config.iconcolor ? config.iconcolor : "white",
                        tittleColor: config.tittle ? config.tittle : "white",
                        letterColor: config.fontcolor ? config.fontcolor : "white",
                        mainColor: config.maincolor ? mainColorReal : [82, 81, 81, 0.691],
                        secondaryColor: config.secondarycolor ? secondaryColorReal : [82, 81, 81, 0.3],
                        backgroundImg: config.backgrounddefault ? config.backgrounddefault : "default.jpeg",
                        logo: config.logo
                    })
                    Swal.fire({
                        icon: 'success',
                        title: 'Bienvenido',
                        text: 'Has iniciado sesión en ' + user.empresa.replace("_", " ").replace("_", " "),
                        confirmButtonText: 'Aceptar',
                    }).then((result) => {
                        if (result.isConfirmed) {
                            // console.log("infoUser Login: ", infoUser)
                            if (dataResponse.data[0].roles === 'SuperUsuario') {
                                window.location.href = './Crear_Empresa';
                            } else {
                                window.location.href = './Cotizaciones';
                            }
                        }
                    })
                } else if ((201 === dataResponse.status)) {
                    Swal.fire({
                        icon: 'error',
                        title: 'Error!',
                        text: dataResponse.message,
                    })
                } else {
                    Swal.fire({
                        icon: 'error',
                        title: 'Error!',
                        text: 'Algo salio mal',
                    })
                }
            }).catch((error) => {
                setDisabledBottom(false)
                console.log("Error: " + error)
                Swal.fire({
                    icon: 'error',
                    title: 'Error!',
                    text: 'Hemos tenido un problema de conexion',
                })
            })
        setDisabledBottom(false)

    }

    return (
        <>
            {
                (disabledBottom) && (
                    <div className="backgroundLoader">
                        <Loader />
                    </div>
                )
            }
            <div className="containerLogoLoginAdd">
                <p className='titleWithoutCompany'><img src={logo} alt='Logo' className='logoAppCotiza' /></p>
            </div>
            <div id='overallContainerLogin' className='overallContainerLogin imageBackground'>
                <div className='containerGifAndLogin'>


                    <div className='containerLoginRight'>
                        {(showVideo === false) ? (
                            <div className='containerMainLogin'>
                                <div className='drawerFormLogin'>
                                    <p className='titleWithoutCompany'>INICIO DE SESIÓN</p>
                                    <form className='ContainerProfile' autoComplete="off">
                                        <div className="inputLoginContainer">
                                            <input
                                                type="email"
                                                name="user-email"
                                                id="user-email"
                                                onChange={handleEmail}
                                                className="inputLogin"
                                                placeholder=" "
                                                autoComplete="off"
                                            />
                                            <label htmlFor="email">Correo electrónico</label>
                                        </div>
                                        <div className="inputLoginContainer">
                                            <input
                                                type={visibleKey ? 'password' : 'text'}
                                                name="user-password"
                                                id="user-password"
                                                onChange={handlePassword}
                                                className="inputLogin"
                                                placeholder=" "
                                                autoComplete="off"
                                            />
                                            <label htmlFor="password">Contraseña</label>
                                            <NavLink onClick={() => { visibleKey ? setVisibleKey(false) : setVisibleKey(true) }}>
                                                <img src={visibleKey ? invisible : visible} alt="Key" className='password3'></img>
                                            </NavLink>
                                        </div>
                                        {/* <em className='forgetPassword'>¿Olvidates tu contraseña?</em> */}
                                        <NavLink to="/RecuperarContraseña" className='whatsappApi'>
                                            <em className='forgetPassword2'>Recuperar Contraseña<div className='iconWhatsapp'><FaKey  /> </div></em>
                                        </NavLink>
                                        <a href="https://api.whatsapp.com/send?phone=3116663708&text=Quiero saber mas acerca de Appcotiza." className='whatsappApi'>
                                            <em className='forgetPassword'>Contáctanos<div className='iconWhatsapp'><FaWhatsapp /> </div></em>
                                        </a>
                                    </form>
                                    <div className="buttonContainer">
                                        <button onClick={Loginprofile} className="button neon_EffectLogin"><p>Iniciar</p></button>
                                    </div>
                                </div>
                                <em className='textAppcotizaLoginFooter'>¿Que es Appcotiza? <div onClick={handleVideo} className='watchVideo'>Ver video</div> </em>

                            </div>
                        ) : (
                            <div className='containerVideo'>
                                <video controls={true}
                                    autoPlay={true}
                                    className='videoTag'
                                    loop={true}
                                >
                                    <source src={urlHomeVideo} type="video/mp4"></source>
                                </video>
                                <button className="button neon_EffectLogin" onClick={handleVideo}><p>Cerrar</p></button>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </>
    )
}

export default Login